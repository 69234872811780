class Info {

	constructor() {

		this.autoReset = true;

		this.frame = 0;
		this.calls = 0;

		this.render = {
			calls: 0,
			frameCalls: 0,
			drawCalls: 0,
			triangles: 0,
			points: 0,
			lines: 0,
			timestamp: 0,
			previousFrameCalls: 0,
			timestampCalls: 0
		};

		this.compute = {
			calls: 0,
			frameCalls: 0,
			timestamp: 0,
			previousFrameCalls: 0,
			timestampCalls: 0
		};

		this.memory = {
			geometries: 0,
			textures: 0
		};

	}

	update( object, count, instanceCount ) {

		this.render.drawCalls ++;

		if ( object.isMesh || object.isSprite ) {

			this.render.triangles += instanceCount * ( count / 3 );

		} else if ( object.isPoints ) {

			this.render.points += instanceCount * count;

		} else if ( object.isLineSegments ) {

			this.render.lines += instanceCount * ( count / 2 );

		} else if ( object.isLine ) {

			this.render.lines += instanceCount * ( count - 1 );

		} else {

			console.error( 'THREE.WebGPUInfo: Unknown object type.' );

		}

	}

	updateTimestamp( type, time ) {

		if ( this[ type ].timestampCalls === 0 ) {

			this[ type ].timestamp = 0;

		}


		this[ type ].timestamp += time;

		this[ type ].timestampCalls ++;


		if ( this[ type ].timestampCalls >= this[ type ].previousFrameCalls ) {

			this[ type ].timestampCalls = 0;

		}


	}

	reset() {

		const previousRenderFrameCalls = this.render.frameCalls;
		this.render.previousFrameCalls = previousRenderFrameCalls;

		const previousComputeFrameCalls = this.compute.frameCalls;
		this.compute.previousFrameCalls = previousComputeFrameCalls;


		this.render.drawCalls = 0;
		this.render.frameCalls = 0;
		this.compute.frameCalls = 0;

		this.render.triangles = 0;
		this.render.points = 0;
		this.render.lines = 0;


	}

	dispose() {

		this.reset();

		this.calls = 0;

		this.render.calls = 0;
		this.compute.calls = 0;

		this.render.timestamp = 0;
		this.compute.timestamp = 0;
		this.memory.geometries = 0;
		this.memory.textures = 0;

	}

}


export default Info;
